<template>
  <div class="topBar">
    <img :src="img" alt="Guarani AFV" v-if="img.length" />
    <span class="enterpriseDescription_line_bold" v-else
      >Empresa: {{ enterpriseName }}</span
    >
  </div>
</template>

<script>
export default {
  name: "TopBarOnlyLogo",
  data: () => ({

    enterpriseName: ""
  }),
  computed: {
    img() {
      return this.$store.state.data.imagemEmpresa ? this.$store.state.data.imagemEmpresa : require("../assets/images/logo-orcamento.png")
    }
  },
  mounted() {
    this.enterpriseName = this.$store.state.data.nomeEmpresa
  }
}
</script>

<style scoped>
.enterpriseDescription_line_bold {
  color: var(--unnamed-color-17100d);
  text-align: center;
  font-family: "Roboto";
}
.topBar {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  box-shadow: #16983f79 0px 1px 2px;
}
.topBar > img {
  width: 100px;
  height: 26px;
}
</style>