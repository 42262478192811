export const searchFilter = (items, filters, ordenacao) => {
    const filteredItems = items.filter((produto) => { 
        const checkProps = Object.keys(filters).every((property) => {
            // ignorar filtros vazios
            if(!filters[property]) return true;

            // se usuário estiver pesquisando na barra de pesquisa
            if (property === "busca") {
                return produto.descricao.toLowerCase().includes(filters[property].toLowerCase()) || produto.codigo.toLowerCase().includes(filters[property].toLowerCase());
            }

            // se usuário estiver pesquisando na barra lateral
            return produto.detalhesProduto[property] === filters[property];
        });

        return checkProps;
    });

    if (ordenacao === "ref") {
        filteredItems.sort((a, b) => {
            return a.codigo - b.codigo;
        });
    } else if (ordenacao === "desc") {
        filteredItems.sort((a, b) => {
            return a.descricao.localeCompare(b.descricao);
        });
    }

    return filteredItems;
}