<template>
  <div class="header_top">
    <div class="minimalContent" v-show="showMinimal">
      <div class="headerTopCol">
        <div class="enterpriseDescription">
          <span class="enterpriseDescription_line_bold">{{
            stringEmptyToHypen(this.$store.state.data.nomeEmpresa)
          }}</span>
        </div>
      </div>
      <div class="headerTopCol">
        <div class="clientDescription">
          <span class="clientDescription_line"
            >Cliente:
            {{ stringEmptyToHypen(this.$store.state.data.clienteRazaoSocial) }}
          </span>
        </div>
      </div>
      <md-button class="md-icon-button arrowUp" @click="showMinimalHeader">
        <md-icon v-show="!showMinimal">keyboard_arrow_up</md-icon>
        <md-icon v-show="showMinimal">keyboard_arrow_down</md-icon>
      </md-button>
    </div>

    <div class="maxContent" v-show="!showMinimal">
      <div class="headerTopCol">
        <div class="enterpriseDescription">
          <img
            :src="getImage(this.$store.state.data.imagemEmpresa)"
            alt=""
            class="enterpriseLogo"
            v-if="
              this.$store.state.data.imagemEmpresa === '' ||
              this.$store.state.data.imagemEmpresa === undefined ||
              this.$store.state.data.imagemEmpresa === null
                ? false
                : true
            "
          />
        </div>
        <span class="enterpriseDescription_line_bold">{{
          stringEmptyToHypen(this.$store.state.data.nomeEmpresa)
        }}</span>
        <span
          class="enterpriseDescription_line"
          v-show="
            stringEmptyToHypen(this.$store.state.data.representanteNome) === '-'
              ? false
              : true
          "
          >{{ stringEmptyToHypen(this.$store.state.data.representanteNome) }}
        </span>
        <span
          class="enterpriseDescription_line"
          v-show="
            stringEmptyToHypen(this.$store.state.data.cnpjEmpresa) === '-'
              ? false
              : true
          "
        >
          {{ stringEmptyToHypen(this.$store.state.data.cnpjEmpresa) }}</span
        >
      </div>

      <div class="headerTopCol clientDescription">
        <span class="clientDescription_line"
          >Cliente:
          {{
            stringEmptyToHypen(this.$store.state.data.clienteRazaoSocial)
          }}</span
        >
        <span class="clientDescription_line"
          >Email: {{ stringEmptyToHypen(this.$store.state.data.clienteEmail) }}
        </span>
        <span class="clientDescription_line"
          >Tipo de ordem:
          {{ stringEmptyToHypen(this.$store.state.data.tipoOrdem) }}
        </span>
        <span class="clientDescription_line"
          >Tipo de frete:
          {{ stringEmptyToHypen(this.$store.state.data.tipoFrete) }}
        </span>
        <span class="clientDescription_line"
          >Forma de pagamento:
          {{ stringEmptyToHypen(this.$store.state.data.formaPagamento) }}
        </span>
        <span class="clientDescription_line"
          >Condição de pagamento:
          {{ stringEmptyToHypen(this.$store.state.data.condicaoPagamento) }}
        </span>

        <span class="clientDescription_line expireDate">
          Este orçamento expira no dia:
          {{ this.$store.state.data.dataExpira }}</span
        >
        <md-button class="md-icon-button arrowUp" @click="showMinimalHeader">
          <md-icon v-show="!showMinimal">keyboard_arrow_up</md-icon>
          <md-icon v-show="showMinimal">keyboard_arrow_down</md-icon>
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import stringEmptyToHypen from "../../modules/stringEmptyToHyphen";
import getImage from "../../utils/getImage"
export default {
  name: "HeaderTop",
  data() {
    return {

      stringEmptyToHypen,
    };
  },
  computed: {
    showMinimal() {
      return this.$store.state.headerShowMinimal
    },
    data() {

      return this.$store.state.data
    }
  },
  methods: {
    getImage,
    showMinimalHeader() {
      this.$store.commit('setHeaderShowMinimal', !this.showMinimal)
      this.$emit("show-minimal", this.showMinimal)
    }
  },
};
</script>

<style scoped>
.expireDate {
  align-items: center;
  color: #d9290b !important;

  text-align: right;
}
.headerTopCol {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.minimalContent {
  padding-bottom: 8px !important;
}
.minimalContent,
.maxContent {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  padding: 18px 0px;
  width: 1130px;
}
@media (max-width: 1139px) {
  .minimalContent,
  .maxContent {
    padding: 18px 10px;
    width: 100%;
  }
  .header_top {
    align-items: flex-start;
    display: flex;
    position: relative;
    width: 100%;
  }
}
.header_top {
  align-items: flex-start;
  display: flex;
  position: relative;
}
.enterpriseLogo {
  height: 56px;
  margin-bottom: 10px;
  width: 100px;
  object-fit: contain;
}
.arrowUp {
  bottom: 10px;
  position: absolute;
  right: 0px;
}
.minimalContent > .arrowUp {
  bottom: 0px;
}
.enterpriseDescription_line_bold {
  color: var(--unnamed-color-17100d);
  font: normal Segoe UI;
  font-weight: 500;
  text-align: left;
}
.enterpriseDescription_line {
  color: var(--unnamed-color-17100d);
}
.clientDescription_line {
  color: #17100d;
  font: normal normal normal 13px/16px Segoe UI;
  font-weight: 500;
  letter-spacing: 0px;
  margin-bottom: 3px;
  opacity: 1;
  text-align: left;
}
</style>
