<template>
  <div class="moreDetails">
    <div class="topBar">
      <span @click="() => this.$router.back()">
        <md-icon>arrow_back</md-icon>
        <h3>Mais informações</h3>
      </span>
    </div>
    <Header @show-minimal="showMinimal" :minimal="true" />

    <div :class="{ container: true, minimal: isMinimal, maximo: !isMinimal }">
      <div style="width: 100%; color: #000; margin-bottom: 10px">
        <button @click="$router.back()" class="btnBack">&lt; Voltar</button>
      </div>
      <div class="productHeader">
        <LightGallery
          :images="images"
          :index="index"
          :disable-scroll="true"
          @close="index = null"
        />

        <div class="galeryProduct">
          <div style="height: 300px" @click="index = 0">
            <img
              class="galeryProductListImage"
              :src="getImage(productSelected.imagem)"
            />
          </div>

          <ul
            class="galeryProductList"
            v-show="Array.isArray(productSelected.imagem)"
          >
            <li
              class="galeryProductListItem"
              v-for="(thumb, thumbIndex) in images"
              :key="thumbIndex"
              :id="thumbIndex"
              @click="() => setOrderImages(thumbIndex)"
              :style="{ right: currentRight + 'px' }"
            >
              <img
                class="galeryProductListImage"
                :class="{
                  minimal: thumbIndex >= 0 ? true : false,
                }"
                :src="thumb.url"
              />
            </li>
          </ul>
          <div v-show="images.length < 4 ? false : true">
            <button class="prev" @click="prev">&#10094;</button>

            <button class="next" @click="next">&#10095;</button>
          </div>
        </div>

        <div class="productDataContainer">
          <div class="productData">
            <span class="productCod">{{ productSelected.codigo }}</span>
            <h1 class="productName">{{ productSelected.descricao }}</h1>
            <h3 class="productCodBar">
              Cód. Barras: {{ productSelected.ean13 }}
            </h3>

            <div class="productPrimary">
              <span class="productValuePrimary">Valor s/impostos</span>
              <span class="productValueSemImposto">
                {{
                  new Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }).format(productSelected.preco)
                }}</span
              >
            </div>

            <span class="productValueComImposto"
              >Valor c/impostos:
              {{
                new Intl.NumberFormat("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }).format(productSelected.precoComImpostos)
              }}</span
            >
          </div>

          <div class="quantityContainer">
            <div class="quantityContainerHeader">Quantidade</div>
            <div class="counterContainer">
              <TableQuantityCounter
                v-show="
                  !this.$store.state.data.items[productId].segregacoes.length
                "
                :item="this.$store.state.data.items[productId]"
              />

              <TableSegregation
                v-show="
                  this.$store.state.data.items[productId].segregacoes.length > 0
                "
                :item="this.$store.state.data.items[productId]"
                :id="productId"
              />

              <TableButtonsAction :item="productId" :disabledInfo="true" />
            </div>
          </div>
        </div>
      </div>

      <div class="productDescription" v-if="productSelected.detalhesProduto">
        <div class="tabs">
          <!-- <button
            class="tabsButtons"
            :class="{ active: tabs === 0 ? true : false }"
            @click="setTabs(0)"
          >
            detalhes
          </button> -->
          <button class="tabsButtons active" :style="{ cursor: 'default' }">
            ficha técnica
          </button>
        </div>
        <div class="tabsContent">
          <div>
            <ul class="listDatasheet">
              <li
                v-for="(item, index) in fichaDeDados"
                :key="index"
                class="datasheetItem"
              >
                <span class="datasheetItemCategory">{{ item.categ }}</span>
                <span class="datasheetItemDescription">{{ item.item }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="productDescriptionNotFound" v-else>
        ficha tecnica não disponivel
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header/Header.vue'

import { LightGallery } from 'vue-light-gallery';
import TableSegregation from '@/components/TableAndCardProducts/TableSegregation.vue';
import TableQuantityCounter from '@/components/TableAndCardProducts/TableQuantityCounter.vue';
import TableButtonsAction from "@/components/TableAndCardProducts/TableButtonsAction";
import ProductNotHasImage from '@/assets/images/placeholder-produto.png'
import getImage from "@/utils/getImage.js"

export default {
  name: "MoreInformation",
  components: {
    Header,
    LightGallery,
    TableSegregation,
    TableQuantityCounter,
    TableButtonsAction,
  },
  data() {


    return {
      images: [],
      currentRight: 0,
      index: null,
      productId: null,
      tabs: 0,


    };
  },
  computed: {
    productSelected() {
      return this.$store.state.data.items[this.productId]
    },
    isMinimal() {
      return this.$store.state.headerShowMinimal
    },
    fichaDeDados() {
      let keys = Object.keys(this.productSelected.detalhesProduto)

      return this.productSelected !== null ? keys.map(item => {
        let referenciaDeDados = [
          { original: "tempoValidade", to: "tempo de validade" },
          { original: "codigoCest", to: "codigo cest" },
          { original: "descricao", to: "descriminação" },
          { original: "qtdeEmbalagem", to: "qtde Embalagem" },
          { original: "codigoMarca", to: "codigo Marca" },
          { original: "codigoProduto", to: "codigo Produto" },
          { original: "pesoBruto", to: "peso Bruto" },
          { original: "referencia", to: "referência" },
          { original: "codFornecedor", to: "código do fabricante" },
          { original: "descFornecedor", to: "fabricante" }
        ]

        if (item !== "fichaDados") {
          let label = referenciaDeDados.find(dado => dado.original === item)

          return {
            item: this.productSelected.detalhesProduto[item],
            categ: label !== undefined ? label.to : item,
          }
        }


      }).filter(item => item !== undefined) : null
    }
  },
  methods: {
    getImage,
    showMinimal(event) {
      this.$store.commit('setHeaderShowMinimal', event)
    },
    setOrderImages(imageId) {
      this.curent = this.images[imageId]
    },

    next: function () {
      if (this.currentRight >= (this.images.length * 100 - 4 * 100)) {
        this.currentRight = this.images.length * 100 - 4 * 100
      } else {
        this.currentRight += 100;
      }
    },
    prev: function () {
      if (this.currentRight <= 0) {
        this.currentRight = 0
      } else {
        this.currentRight -= 100;
      }
    },
  },
  async mounted() {

    if (this.$store.state.flagControl === true) {
      await this.$store.dispatch("getData", {
        cpfCnpj: this.$route.params.cpfCnpj,
        numPedido: this.$route.params.numPedido,
        tradeAgent: this.$route.params.tradeAgent,
        cpfCnpjCustomer: this.$route.params.cpfCnpjCustomer
      });
      this.$store.state.flagControl = false
    }

    this.productId = this.$route.params.item;
    //this.productSelected = this.$store.state.data.items[this.productId]

    if (Array.isArray(this.productSelected) === false) {
      if (this.productSelected.imagem.toString() === "") {
        this.images.push({ title: this.productSelected.descricao, url: ProductNotHasImage })
      }
      else {

        this.images.push({ title: this.productSelected.descricao, url: `${this, getImage(this.productSelected.imagem)}` })


      }
    } else {
      for (let count = 0; count <= this.productSelected.imagem.length; count++) {
        this.images.push({ title: this.productSelected.descricao, url: this.productSelected.imagem[count] })
      }
    }


    window.scrollTo(0, 0);
    localStorage.setItem('setCurrentRoute', this.$route.params.item);

  }
}
</script>

<style>
.light-gallery__text {
  margin: -50px -54px !important;
  background: rgb(0 0 0 / 0%) !important;
}
</style>
<style scoped >
.md-icon {
  margin: 0;
}
.md-icon.md-theme-default.md-icon-font {
  color: #fff !important;
}
</style>

<style scoped>
.topBar {
  width: 100vw;
  background: #3e6e31;
  height: 60px;
  display: none;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  color: #fff;
}
.topBar > span {
  display: flex;
  align-items: center;
}
.topBar > span > h3 {
  margin-left: 15px;
  font-weight: 300;
  font-size: 17px;
}
.listDatasheet {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 15px;
  width: 100%;
}
.datasheetItem {
  list-style: none;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  width: inherit;
  padding: 3px 15px;
  font-family: "Roboto", sans-serif;
}
.datasheetItem:nth-child(4n),
.datasheetItem:nth-child(4n -1) {
  background: #f8f8f8;
}

.datasheetItemCategory {
  text-transform: uppercase;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
}
.datasheetItemDescription {
  text-align: right;
  word-break: break-all;
  font-size: 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.moreDetails {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;
}
.galeryProduct {
  position: relative;
  cursor: pointer;
}
.galeryProductList {
  display: grid;
  width: calc(100px * 6);
  position: relative;
  grid-auto-columns: 100px;
  grid-auto-flow: column;
  overflow: hidden;
  transition: 0.2s linear;
  position: relative;
}
.galeryProductListItem {
  list-style: none;
  position: relative;
  transition: 0.2s linear;
}
.galeryProductListImage {
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.galeryProductListImage.first {
}
.galeryProductListImage.minimal {
  height: 100px;
}
.prev,
.next {
  transition: 0.5s linear;
  color: #fff;
  font-size: 20px;
  position: absolute;
  bottom: 40px;
}
.prev {
  left: 10px;
}
.next {
  right: 10px;
}
.minimal {
  padding-top: 80px !important;
}
.maximo {
  padding-top: 200px !important;
}
.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1130px;
  padding: 0px;
}
@media (max-width: 1139px) {
  .container {
    padding: 0 10px;
  }
}
.productHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.productDataContainer {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}
.productData {
  margin-top: 10px;
  margin-bottom: 10px;
}
.productCod {
  color: #707070;
  font-size: 12px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.productName {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;

  word-wrap: break-word;
  word-break: break-all;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 22px;
}
.productCodBar {
  color: #707070;
  font-size: 12px;
  margin-bottom: 30px;
}
.productPrimary {
  display: flex;
  flex-direction: column;
}
.productValuePrimary {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
}
.productValueSemImposto {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
}
.productValueComImposto {
  color: #707070;
  font-size: 12px;
  margin-bottom: 10px;
}

.quantityContainer {
  background: #f7f7f7;
  color: #707070;
}
.quantityContainerHeader {
  background: #169840;
  color: #ffffff;
  padding: 5px 10px;
}
.counterContainer {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productDescription {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
}
.productDescriptionNotFound {
  display: flex;
  margin-top: 150px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #9a9a9a;
}
.tabs {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}
.tabsButtons {
  text-transform: uppercase;
  font-family: "Oxygen", sans-serif;
  background: #ffffff;
  border: solid #e4e4e4 1px 0 1px 0;
  color: #707070;
  text-align: left;
  padding: 10px 15px;
  margin: 5px 0;
  width: 150px;
}
.tabsButtons.active {
  background: #3e6e31;
  color: #ffffff;
}
.tabsContent {
  width: 100%;
}
.tabsContent > div {
  padding: 10px 0;
  border-top: solid #3e6e31 2px;
  border-bottom: solid #3e6e31 2px;
  width: 100%;
}
.btnBack {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

@media (max-width: 768px) {
  .tabsContent > div {
    padding: 10px 0;
    border-top: solid #3e6e3100 0px;
    border-bottom: solid #3e6e3100 0px;
    width: 100%;
  }
  .btnBack {
    display: none;
  }
  .topBar {
    display: flex;
  }
  .maximo {
    padding-top: 0px !important;
  }
  .container {
    padding: 0 20px;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .productHeader {
    flex-direction: column;
    width: 100%;
  }
  .galeryProductList {
    width: calc(100%);
  }
  .productDescription {
    flex-direction: column;
    margin-top: 10px;
  }
  .tabs {
    flex-direction: row;
    margin-right: 0;
  }
  .tabsButtons {
    flex: 1;
  }
  .listDatasheet {
    grid-template-columns: auto;
  }
  .datasheetItem {
    width: 100%;
  }
  .datasheetItem:nth-child(4n),
  .datasheetItem:nth-child(4n -1) {
    background: #ffffff;
  }
  .datasheetItem:nth-child(odd) {
    background: #f8f8f8;
  }
  .productDataContainer {
    margin-left: 0;
    width: 100%;
  }
}
</style>