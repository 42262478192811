<template>
  <div class="headerMobile">
    <span class="title">ORÇAMENTO WEB</span>

    <div class="headerActions">
      <button @click="openFilter">
        <md-icon class="icons">filter_list</md-icon>
      </button>

      <button @click="seachInputFocus">
        <md-icon class="icons">search</md-icon>
      </button>

      <button @click="showChecks">
        <md-icon class="icons">check</md-icon>
      </button>
      <button class="moreVert" @click="showMore = !showMore" @keydown.esc="showMore = false">
        <md-icon class="icons">more_vert</md-icon>
      </button>

      <transition name="fade">
        <div class="menuContainer" v-show="showMore">
          <ul class="menuList">
            <li class="menuItem">
              <md-button @click="eraseAllQuantity">Zerar quantidades</md-button>
            </li>
            <li class="menuItem">
              <md-button @click="refreshAllQuantity">Restaurar quantidades</md-button>
            </li>
            <div class="menuDivisor"></div>
            <li class="menuItem" @click="() => {
              save();
              showMore = !showMore;
            }
              ">
              <md-button>Salvar orçamento</md-button>
            </li>
            <li class="menuItem">
              <md-button @click="() => {
                showChecks();
                showMore = !showMore;
              }
                ">Enviar p/representante</md-button>
            </li>
          </ul>
        </div>
      </transition>

      <transition name="fade">
        <div class="searchOpacity" v-show="showSearch" @click="showSearch = false"></div>
      </transition>

      <transition name="fade">
        <div class="searchOpacity" v-show="showMore" @click="showMore = false"></div>
      </transition>

      <transition name="fade">
        <div class="searchBar" v-show="showSearch">
          <div class="searchIconClose" @click="closeAndResetInput" @keydown.esc="showSearch = !showSearch">
            <md-icon class="iconClose">close</md-icon>
          </div>
          <input type="text" class="search" placeholder="Pesquise por código ou descrição" v-model.trim="search"
            @input="searchOnTable" @keydown.enter="() => {
              searchOnTable();
              showSearch = !showSearch;
            }
              " @keydown.esc="showSearch = !showSearch" ref="inputSearch" />
        </div>
      </transition>
      <ModalConfirmation ref="modal" />
    </div>
  </div>
</template>

<script>
import { searchFilter } from '../../utils/searchFilter';
import { debounce } from '../../utils/debounce';
import ModalConfirmation from '../Modals/ModalConfirmation.vue';

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  return items.filter((item) => {
    let reg = new RegExp(toLower(term))

    return toLower(item.descricao).match(reg) || toLower(item.codigo).match(reg)

  })

}

export default {
  name: "HeaderMobile",
  components: {
    ModalConfirmation,
  },
  data() {
    return {
      showSearch: false,
      showMore: false,
      showCheck: false,
      search: "",
    };
  },
  methods: {
    openFilter() {
      this.$store.commit("setShowFilter", true);
    },
    seachInputFocus() {
      this.showSearch = !this.showSearch
      this.$refs.inputSearch.focus()
    },
    save() {
      this.$store.dispatch("saveAndSendEstimate", 'save')
    },
    showChecks() {
      this.$store.commit("setModalSend", true)
    },
    closeAndResetInput() {
      this.search = "";
      this.showSearch = false;
      this.$store.commit(
        "setItems",
        searchByName(this.$store.state.data.items, "")
      );
    },
    searchOnTable: debounce(function () {
      this.$store.commit("updateSearch", this.search);

      this.$store.commit(
        "setItems",
        searchFilter(this.$store.state.data.items, this.$store.state.filtrosAtivos, this.$store.state.orderItems)
      );

    }, 300),
    eraseAllQuantity() {
      this.$store.commit("eraseAllQuantity")
    },
    refreshAllQuantity() {
      this.$store.commit("refreshAllQuantity")
    }
  },
};
</script>

<style></style>

<style scoped>
.swal2-container {
  display: flex;
  position: fixed;
  z-index: 2000 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}

.headerMobile {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.moreVert {
  bottom: 0;
  position: relative;
  right: 0;
}

.menuContainer {
  background: #ffffff;
  border-radius: 2px;
  box-shadow: #00000029 0px 2px 4px;
  padding: 5px 0;
  position: absolute;
  right: 2%;
  top: 80%;
  z-index: 3000;
}

.menuList {}

.menuItem {
  color: #000000;
  list-style: none;
  padding: 5px 10px;
}

.menuDivisor {
  background: #00000029;
  height: 2px;
  margin: 5px 0;
  width: 100%;
}

@media (max-width: 768px) {
  .headerMobile {
    align-items: center;
    background: #3e6e31;
    box-shadow: #00000033 0px 2px 3px;
    color: #fff;
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    height: 60px;
    justify-content: space-between;
    left: 0;
    padding: 5px 10px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2000;
  }

  .headerActions {}

  .headerActions>.icons {
    height: 40px;
    width: 40px;
  }

  .headerActions>button {
    margin: 0 5px;
  }

  .md-button .md-icon {
    color: #fff !important;
    font-size: 30px !important;
  }

  .headerActions>button>.md-icon {
    color: #fff !important;
    font-size: 30px !important;
  }

  .searchOpacity {
    background: #00000033;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0px;
    width: 100vw;
  }

  .searchBar {
    align-items: center;
    background: #fff;
    display: flex;
    height: 60px;
    left: 0;
    padding-left: 5px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 3000;
  }

  .searchIconClose>.md-icon {
    font-size: 30px !important;
    height: 50px;
    width: 50px;
  }

  .search {
    color: #757575;
    font-family: "Oxygen", sans-serif;
    font-size: 15px;
    font-weight: 700;
    height: 60px;
    padding: 0 5px;
    width: 100vw;
  }

  .search:focus {
    outline: none;
  }
}
</style>