<template>
  <div class="quantityCounter">
    <div
      class="counterButton less"
      :style="{
        backgroundImage: 'url(' + require('@/assets/icons/menos.svg') + ')',
      }"
      @click="() => less(item)"
    ></div>
    <input
      min="0"
      type="number"
      class="quantity"
      step="any"
      v-model.number="getItems"
      :class="{ red: getItems === 0 ? true : false }"
      @blur="handleQuantity"
      @keyup.enter="handleQuantity"
      @keypress="isNumber($event)"
    />

    <div
      class="counterButton more"
      :style="{
        backgroundImage: 'url(' + require('@/assets/icons/mais.svg') + ')',
      }"
      @click="() => more(item)"
    ></div>
  </div>
</template>

<script>
import isNumber from '../../utils/isNumber'
export default {
  name: "TableQuantityCounter",
  props: ["item"],

  computed: {
    getItems: {

      get: function () {

        return this.$store.state.data.items[this.item.positionArray].quantidade
      },
      // setter
      set: function (newValue) {

        this.$store.state.data.items[this.item.positionArray].quantidade = newValue
      }

    }


  },
  methods: {
    isNumber,
    handleQuantity(event) {
      const permiteVenderUnidade = this.$store.state.permiteVenderUnidade
      const embalagemDecimal = this.item.embalagemDecimal

      let inputValue = event.target.value;
      inputValue = inputValue.replace(/^0+/, '')
      inputValue = Number(inputValue.replaceAll(",", "."));


      if (inputValue <= 0) {
        inputValue = 0
      }
      function isFloat(n) {
        return n != "" && !isNaN(n) && Math.round(n) != n;
      }

      let result;

      if (isFloat(inputValue) || inputValue % this.item.multiplo !== 0) {
        let round, rest;

        round = Math.round(inputValue);
        rest = round % this.item.multiplo;
        if (embalagemDecimal === false || permiteVenderUnidade === false) {
          if (rest !== 0) {
            result = round - rest + this.item.multiplo;
          } else {
            result = round;
          }
        }

        else {
          result = inputValue
        }
        this.$store.state.data.items[this.item.positionArray].quantidade = permiteVenderUnidade === false ? result.toFixed(3) : Math.round(inputValue).toFixed(3)
        this.$store.commit("setQuantityItem")
      }
      else {
        result = inputValue
        this.$store.state.data.items[this.item.positionArray].quantidade = result.toFixed(3);
        this.$store.commit("setQuantityItem")
      }

    },
    more(item) {
      if (this.$store.state.permiteVenderUnidade === true) {
        this.$store.state.data.items[item.positionArray].quantidade = (Number(this.$store.state.data.items[item.positionArray].quantidade) + 1).toFixed(3);
      } else {
        this.$store.state.data.items[item.positionArray].quantidade = (Number(this.$store.state.data.items[item.positionArray].quantidade) + 1 * this.$store.state.data.items[item.positionArray].multiplo).toFixed(3);

      }
      this.$store.commit("setQuantityItem")
    },
    less(item) {
      if (Number(this.$store.state.data.items[item.positionArray].quantidade) - 1 * this.$store.state.data.items[item.positionArray].multiplo <= 0) {
        this.$store.state.data.items[item.positionArray].quantidade = 0
      }
      else {
        if (this.$store.state.permiteVenderUnidade === true) {
          this.$store.state.data.items[item.positionArray].quantidade = (Number(this.$store.state.data.items[item.positionArray].quantidade) - 1).toFixed(3);
        } else {
          this.$store.state.data.items[item.positionArray].quantidade = (Number(this.$store.state.data.items[item.positionArray].quantidade) - 1 * this.$store.state.data.items[item.positionArray].multiplo).toFixed(3);

        }

      }

      this.$store.commit("setQuantityItem")
    },
  },
};
</script>

<style scoped>
.red {
  color: #e80000;
}
.quantityCounter {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.counterButton,
.quantity {
  text-align: center;
}
.counterButton {
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.quantity {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 137px;
}
.quantity:focus {
  outline: none;
}
.less {
  border-right: 1px solid #e4e4e4;
}
.more {
  border-right: 1px solid #e4e4e4;
}
.drop {
  display: block;
}
@media (max-width: 810px) {
  .quantity {
    width: 70px;
  }
}
@media (max-width: 768px) {
  .quantity {
    width: 90%;
  }
}
</style>
