<template>
  <div class="tableSegregation">
    <button @click="show" class="segregationButton">
      {{
        parseInt(
          this.$store.state.data.items[item.positionArray].quantidade * 100
        ) / 100
      }}
      {{ this.$store.state.data.items[item.positionArray].unidade }}
    </button>
    <modal
      :name="`my-first-modal-${id}`"
      classes="modal"
      :close-on-click="true"
      :clickToClose="false"
    >
      <div class="modalHeader">
        <h3>Alterar segregação</h3>
      </div>

      <div class="modalBody">
        <div class="productData">
          <h2 class="productName">{{ item.descricao }}</h2>

          <div class="productDescription">
            <div class="descriptionBox">
              <p>Múltiplo</p>
              <span>{{ item.multiplo }}</span>
            </div>

            <div class="descriptionBox">
              <p>Unidade</p>
              <span>{{ item.unidade }}</span>
            </div>
          </div>
        </div>

        <div class="segregationQuantity">
          <div
            class="wrap"
            v-for="(items, index) in this.$store.state.data.items[
              item.positionArray
            ].segregacoes"
            :key="index"
          >
            <div class="imageSegregation">
              <img :src="getImage(items.imagem)" alt="" />
            </div>
            <div style="flex: 10">
              <p class="unidade">{{ items.descricao }}</p>
              <span v-show="segregaçãoHasMultiplos(items.multiplo) >= 1">
                Múltiplo: {{ segregaçãoHasMultiplos(items.multiplo) }}
              </span>
              <div class="quantityAndSwitch">
                <div class="quantityCounter">
                  <button
                    class="counterButton less"
                    :style="{
                      backgroundImage:
                        'url(' + require('@/assets/icons/menos.svg') + ')',
                    }"
                    @click="
                      () => {
                        less(items, id);
                      }
                    "
                    :disabled="!items.actionSegregation"
                  ></button>
                  <input
                    min="0"
                    type="number"
                    class="quantity"
                    v-model.trim.number="items.volatil"
                    :class="{
                      disable: items.actionSegregation === true ? false : true,
                    }"
                    @blur="(e) => handleQuantity(e, index, item, items)"
                    @keyup.enter="(e) => handleQuantity(e, index, item, items)"
                    @keypress="isNumber($event)"
                    :disabled="!items.actionSegregation"
                  />

                  <button
                    class="counterButton more"
                    :style="{
                      backgroundImage:
                        'url(' + require('@/assets/icons/mais.svg') + ')',
                    }"
                    @click="
                      () => {
                        more(items, id);
                      }
                    "
                    :disabled="!items.actionSegregation"
                  ></button>
                </div>
                <md-switch
                  v-model="items.actionSegregation"
                  @change="(e) => switchClick(e, index, items, item)"
                  :disabled="item.disabled"
                ></md-switch>
              </div>
            </div>
          </div>
        </div>

        <div v-show="quantidaMaxSegregacoes" class="qtdMaxSegregacao">
          Selecione no máximo {{ quantidaMaxSegregacoes }} segregações
        </div>

        <div class="totalQuantity">
          <p>Quantidade total</p>
          <span>{{
            parseInt(
              this.$store.state.data.items[
                item.positionArray
              ].segregacoes.reduce((old, news) => old + news.volatil, 0) * 100
            ) / 100
          }}</span>
        </div>

        <div class="modalFooter">
          <md-button class="cancel" @click="() => hide(id)">cancelar</md-button>
          <md-button class="save" @click="updateQuantity">confirmar</md-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import stringEmptyToHypen from "@/modules/stringEmptyToHyphen";
import getImage from '../../utils/getImage'
import isNumber from '../../utils/isNumber'
import Vue from "vue";
export default {
  name: "TableSegregation",
  props: ["item", "id"],
  components: {},
  data() {
    return {
      [this.item.codigo]: false,
    };
  },
  computed: {
    getStore() {
      return this.$store.state.data.itemsPaginate[this.id].segregacoes;
    },
    quantidaMaxSegregacoes() {
      let qtdSelecaoSegregacaoMax = this.$store.state.data.items[this.id]
        .qtdSelecaoSegregacaoMax;
      return qtdSelecaoSegregacaoMax;
    },
  },
  methods: {
    getImage,
    isNumber,
    segregaçãoHasMultiplos(multiple) {
      return multiple;
    },
    switchClick(switchState, segregationPosition, segregation, item) {
      this.$store.state.data.items[item.positionArray].segregacoes[
        segregationPosition
      ].action = switchState;
      let segregacoesAtivas = this.$store.state.data.items[
        item.positionArray
      ].segregacoes.filter((seg) => seg.actionSegregation === true);

      if (item.qtdSelecaoSegregacaoMax > 0) {
        if (segregacoesAtivas.length > item.qtdSelecaoSegregacaoMax) {
          segregation.actionSegregation = false;
          this.$store.state.data.items[item.positionArray].segregacoes[
            segregationPosition
          ].action = false;
          switchState = false;
          Vue.toast(
            `Não é possivel selecionar mais de ${item.qtdSelecaoSegregacaoMax} segregações`,
            {
              id: "my-toast-1",
              className: ["my-toast", "alert"],
              horizontalPosition: "center",
              verticalPosition: "top",
              duration: 3500,
              mode: "queue",
              transition: "fade",
              closeable: false,
            }
          );
          return;
        }
      }

      const segregationTotal = this.$store.state.data.items[item.positionArray]
        .segregacoes.length;
      const segregationSelected = this.$store.state.data.items[
        item.positionArray
      ].segregacoes
        .map((item) => item.action)
        .filter((item) => item === true);
      const segregationSelectedTeste = [];

      for (let count = 0; count < segregationTotal; count++) {
        if (
          this.$store.state.data.items[item.positionArray].segregacoes[count]
            .action === true
        ) {
          segregationSelectedTeste.push(count);
        }
      }

      const quantidade = this.$store.state.data.items[
        item.positionArray
      ].segregacoes.reduce((acc, news) => acc + news.volatil, 0);

      const multiplo = this.$store.state.data.items[item.positionArray]
        .multiplo;
      let quociente = 0;

      let total = null;

      if (this.$store.state.data.permiteVenderUnidade === true) {
        quociente =
          segregationSelected.length === 0
            ? 0
            : Math.floor(
              Number(quantidade) / Number(segregationSelected.length)
            );
        total = Number((Number(quantidade) - 1).toFixed(3));
      } else {
        quociente =
          segregationSelected.length === 0
            ? 0
            : Math.ceil(
              Number(quantidade) /
              Number(multiplo) /
              Number(segregationSelected.length)
            );
        total = Number((Number(quantidade) - Number(multiplo)).toFixed(3));
      }

      this.$store.state.data.items[item.positionArray].segregacoes.map(
        (segregacao) => {
          segregacao.volatil = 0;
        }
      );

      function getParts(sum, length) {
        var left = Math.ceil(sum / length),
          right = Math.floor(sum / length);

        let first = 0;
        if (right > 0) {
          first = (sum - right * length) / right;
        } else {
          first = (sum - right * length) / 1;
        }

        return Array.from({ length }, (_, i) => (i < first ? left : right));
      }

      function getPartsFloat(sum, length) {
        var left = sum / length,
          right = sum / length;

        let first = 0;
        if (right > 0) {
          first = (sum - right * length) / right;
        } else {
          first = (sum - right * length) / 1;
        }
        return Array.from({ length }, (_, i) => (i < first ? left : right));
      }
      let arrayDivider = null;

      if (
        this.$store.state.data.items[item.positionArray].embalagemDecimal ===
        true
      ) {
        arrayDivider = getPartsFloat(quantidade, segregationSelected.length);
      } else {
        arrayDivider = getParts(quantidade, segregationSelected.length);
      }

      if ((quantidade === 1 || quantidade === 2) && switchState === true) {
        for (let count = 0; count < segregationTotal; count++) {
          if (count === 0) {
            this.$store.state.data.items[item.positionArray].segregacoes[
              count
            ].volatil = Number(quantidade);
          } else {
            this.$store.state.data.items[item.positionArray].segregacoes[
              count
            ].volatil = 0;
          }
        }
      } else {
        let sub = 0;
        for (let count = 0; count < segregationTotal; count++) {
          if (
            this.$store.state.data.items[item.positionArray].segregacoes[count]
              .action === true
          ) {
            this.$store.state.data.items[item.positionArray].segregacoes[
              count
            ].volatil = Number(arrayDivider[sub]);
            sub++;
          } else {
            this.$store.state.data.items[item.positionArray].segregacoes[
              count
            ].volatil = 0;
          }
        }
      }

      // for (let i = 0; i < quociente; i++) {
      //   for (let j = 0; j < segregationTotal; j++) {
      //     if (total >= 0 && this.$store.state.data.items[item.positionArray].segregacoes[j].action) {
      //       if (this.$store.state.data.permiteVenderUnidade === true) {
      //         total = total--;
      //         this.$store.state.data.items[item.positionArray].segregacoes[j].volatil = Number((this.$store.state.data.items[item.positionArray].segregacoes[j].volatil + 1).toFixed(3));
      //       } else {
      //         total -= Number(multiplo);
      //         this.$store.state.data.items[item.positionArray].segregacoes[j].volatil = Number((this.$store.state.data.items[item.positionArray].segregacoes[j].volatil + Number(
      //           multiplo
      //         )).toFixed(3));
      //       }

      //     }
      //   }
      // }

      this.$store.state.data.items[
        item.positionArray
      ].quantidade = this.$store.state.data.items[
        item.positionArray
      ].segregacoes.reduce((acc, cur) => {
        return (acc = Number((acc + Number(cur.quantidade)).toFixed(3)));
      }, 0);
    },

    show() {
      this.$modal.show(`my-first-modal-${this.id}`);
    },
    hide(id) {
      for (
        let count = 0;
        count < this.$store.state.data.items[id].segregacoes.length;
        count++
      ) {
        const original = this.$store.state.data.items[id].segregacoes[count]
          .action;
        const teste = this.$store.state.data.items[id].segregacoes[count]
          .quantidade;
        this.$store.state.data.items[id].segregacoes[
          count
        ].actionSegregation = original;
        this.$store.state.data.items[id].segregacoes[count].volatil = teste;
      }

      this.$modal.hide(`my-first-modal-${this.id}`);
      this.item.total = this.item.quantidade;
    },

    handleQuantity(event, position, item, quant) {

      const permiteVenderUnidade = this.$store.state.permiteVenderUnidade;
      const embalagemDecimal = this.item.embalagemDecimal;

      let inputValue = event.target.value;
      inputValue = inputValue.replace(/^0+/, "");
      inputValue = Number(inputValue.replaceAll(",", "."));

      if (inputValue <= 0) {

        inputValue = 0;
        quant.volatil = 0
      }
      function isFloat(n) {
        return n != "" && !isNaN(n) && Math.round(n) != n;
      }

      let result;

      if (permiteVenderUnidade === true) {
        quant.volatil = inputValue;

      } else {
        if (quant.multiplo > 0) {
          if (isFloat(inputValue) || inputValue % quant.multiplo !== 0) {

            let round, rest;

            round = Math.round(inputValue);
            rest = round % quant.multiplo;

            if (embalagemDecimal === false) {

              if (rest !== 0) {

                result = round - rest + quant.multiplo;
              } else {
                result = round;
              }
            } else {
              if (quant.multiplo > 0) {
                result = round - rest + quant.multiplo;
              } else {
                result = inputValue;
              }
            }
            quant.volatil = result;
          }
        } else {
          if (isFloat(inputValue) || inputValue % item.multiplo !== 0) {
            let round, rest;


            round = Math.round(inputValue);
            rest = round % item.multiplo;
            if (embalagemDecimal === false) {
              if (rest !== 0) {
                result = round - rest + item.multiplo;
              } else {
                result = round;
              }
            } else {
              result = inputValue;
            }
            quant.volatil = result;
          }
        }
      }

      this.item.total = this.item.segregacoes.reduce(
        (acc, newValue) => acc + newValue.volatil,
        0
      );
    },
    more(item, id) {
      const permiteVenderUnidade = this.$store.state.permiteVenderUnidade;

      if (item.volatil < 0) {
        item.volatil = 0;
      } else {
        if (permiteVenderUnidade === true) {
          item.volatil = Number(item.volatil + 1);
        } else {
          if (item.multiplo > 0) {
            item.volatil = Number((item.volatil + 1 * item.multiplo).toFixed(3));

          } else {
            item.volatil = Number(item.volatil + 1 * this.item.multiplo);
          }
        }
      }

      this.item.total = this.item.segregacoes.reduce(
        (acc, newValue) => acc + newValue.volatil,
        0
      );
    },
    less(item, id) {
      const permiteVenderUnidade = this.$store.state.permiteVenderUnidade;
      if (item.volatil < 1) {
        item.volatil = 0;
      } else {
        if (permiteVenderUnidade === true) {
          item.volatil = Number(item.volatil - 1);
        } else {
          if (item.multiplo > 0) {
            item.volatil = Number((item.volatil - 1 * item.multiplo).toFixed(3));

          } else {
            item.volatil = Number(item.volatil - 1 * this.item.multiplo);
          }
        }
      }

      this.item.total = this.item.segregacoes.reduce(
        (acc, newValue) => acc + newValue.volatil,
        0
      );
    },

    updateQuantity() {
      for (
        let count = 0;
        count <
        this.$store.state.data.items[this.item.positionArray].segregacoes
          .length;
        count++
      ) {
        if (
          this.$store.state.data.items[this.item.positionArray].segregacoes[
            count
          ].volatil === 0
        ) {
          this.$store.state.data.items[this.item.positionArray].segregacoes[
            count
          ].actionSegregation = false;
        }
        this.$store.state.data.items[this.item.positionArray].segregacoes[
          count
        ].quantidade = this.$store.state.data.items[
          this.item.positionArray
        ].segregacoes[count].volatil;
      }

      this.$store.state.data.items[
        this.item.positionArray
      ].quantidade = this.$store.state.data.items[
        this.item.positionArray
      ].segregacoes.reduce((acc, news) => acc + news.quantidade, 0);

      this.$store.commit("setQuantityItem");
      this.$modal.hide(`my-first-modal-${this.id}`);
    },
  },
};
</script>

<style scoped>
.qtdMaxSegregacao {
  padding: 0px 25px;
  color: #d9290b;
  margin-bottom: 12px;
  font-size: 12px;
}
.disable {
  background: #eee !important;
}
.totalQuantity {
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
}
.totalQuantity p {
}
.totalQuantity span {
  background-color: #f7f7f7;
  color: #707070;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 25px;
  text-align: center;
}
.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}
.modalFooter > button {
  border-radius: 5px;
  font-family: "Oxygen", sans-serif;
  font-weight: 600;
  height: 40px;
  margin: 0 5px;
  min-width: 120px;
  text-transform: uppercase;
}
.modalFooter > .cancel {
  background-color: #e4e4e4;
  color: #17100d;
}
.modalFooter > .save {
  background-color: #169840;
  border-color: #169840;
  color: #fff;
}
.imageSegregation {
  margin-right: 10px;
  width: 50px;
  min-height: 100%;
  margin-bottom: 6px;
}
.quantityCounter {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 10;
}
.counterButton,
.quantity {
  text-align: center;
}
.counterButton {
  height: 40px;
  width: 40px;
}
.quantity {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 80px;
}
.quantity:focus {
  outline: none;
}
.less {
  border-right: 1px solid #e4e4e4;
}
.more {
  border-right: 1px solid #e4e4e4;
}
.drop {
  display: block;
}
.tablesegregation {
  display: flex;
  width: 100%;
  align-items: center;
}
.imageSegregation > img {
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
  object-fit: contain;
}
.modalHeader {
  align-items: center;
  background: #3e6e31;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 100%;
}
.modalHeader > h3 {
  color: #ffffff;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}
.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.productData {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.productName {
  color: #000;
  font-family: "Oxygen", sans-serif;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
}
.productDescription {
  display: flex;
  justify-content: flex-start;
  padding: 0 25px;
  margin-bottom: 15px;
}
.wrap {
  padding: 0 25px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
}
.unidade {
  margin-bottom: -2px;
  font-weight: 500;
}
.quantityAndSwitch {
  display: flex;
  justify-content: space-between;
}
.descriptionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.descriptionBox:nth-child(1) {
  margin-right: 20px;
}
.descriptionBox > p {
  text-align: left;
}
.descriptionBox > span {
  background-color: #f7f7f7;
  color: #707070;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 25px;
  text-align: center;
}
.segregationButton {
  align-items: center;
  background: linear-gradient(180deg, #fff, #f1f1f1);
  border: 1px solid #e6e6e6;
  color: #707070;
  display: flex;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  width: 217px;
}
.tableSegregation {
  width: 100%;
  justify-content: center;
  display: flex;
}
@media (max-width: 810px) {
  .segregationButton {
    width: 150px;
  }
}
@media (max-width: 768px) {
  .segregationButton {
    width: 100%;
  }
}
</style>

<style>
.md-switch {
  margin: 0;
}
.md-switch {
  width: auto;
  margin: 16px 0 16px 0 !important;
  display: inline-flex;
  position: relative;
}
.md-switch.md-theme-default.md-checked .md-switch-container {
  background-color: rgba(22, 152, 64, 0.5) !important;
}
.md-switch.md-theme-default.md-checked .md-switch-thumb {
  background-color: #169840 !important;
}
.vm--container {
  box-sizing: border-box;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0% !important;
  width: 100%;
  z-index: 4000 !important;
  display: flex;
  align-items: center;
}
.vm--modal {
  background-color: #ffffff !important;
  border-radius: 0px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  box-sizing: border-box;
  height: auto !important;
  left: 0 !important;
  margin: 0 auto;
  min-height: 400px !important;
  overflow: scroll;
  position: relative;
  top: 10px !important;
  /* width: 50vw !important; */
  z-index: inherit;
}
@media (max-width: 768px) {
  .vm--modal {
    width: 50vw !important;
  }
}
@media (max-width: 768px) {
  .vm--modal {
    width: 95vw !important;
  }
}
</style>
