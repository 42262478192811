<template>
  <div>
    <div class="tableEmptyState" v-show="itemsLength === 0">
      <h2 class="tableEmptyStateTitle">Sem resultados</h2>
      <p class="tableEmptyStateText">Pesquise novamente</p>
    </div>

    <table class="table" v-show="itemsLength !== 0">
      <thead class="tableHead">
        <tr>
          <th class="tableHeadItem"></th>
          <th class="tableHeadItem"><c-text>Código</c-text></th>
          <th class="tableHeadItem description">Descrição</th>
          <th class="tableHeadItem">Unidade</th>
          <th class="tableHeadItem value hideNearTheMobile">
            <span>Valor sem </span>
            <span>impostos</span>
          </th>

          <th class="tableHeadItem value showNearTheMobile">
            <span>Valor</span>
            <span>s/ impostos</span>
          </th>

          <th class="tableHeadItem value hideNearTheMobile">
            <span>Valor com </span>
            <span>impostos</span>
          </th>

          <th class="tableHeadItem value showNearTheMobile">
            <span>Valor</span>
            <span>c/ impostos</span>
          </th>
          <th class="tableHeadItem quantity">Quantidade</th>
          <th class="tableHeadItem buttonsAction hideNearTheMobile">
            Rest. | Zerar | Info
          </th>
        </tr>
      </thead>

      <tbody class="tableBody">
        <tr
          class="tableRow"
          v-for="(item, index) in items"
          :key="index"
          v-bind:style="getBackground(index)"
        >
          <td class="tableDrop imageProduct">
            <img :src="getImage(item.imagem)" alt="" class="tableCellImg" />
          </td>
          <td class="tableDrop">{{ item.codigo }}</td>
          <td class="tableDrop description">
            <span style="display: block">{{ item.descricao }}</span>
            <span class="descriptionLegend" style="display: block"
              >{{ item.ean13 }}

              <button
                @click="getUrl(item.positionArray)"
                class="moreInformationButton hideNearTheMobile"
              >
                + informações
              </button>
            </span>
          </td>
          <td class="tableDrop">{{ item.unidade }}</td>
          <td class="tableDrop">
            {{
              new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
              }).format(item.preco)
            }}
          </td>
          <td class="tableDrop">
            {{
              new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
              }).format(item.precoComImpostos)
            }}
          </td>
          <td class="tableDrop quantity">
            <TableQuantityCounter
              v-show="!item.segregacoes.length"
              :item="item"
            />

            <TableSegregation
              v-show="item.segregacoes.length > 0"
              :item="item"
              :id="index"
            />
          </td>
          <td class="tableDrop buttons showNearTheMobile">
            <div @click.stop>
              <md-menu
                md-size="medium"
                md-align-trigger
                :mdCloseOnClick="true"
                :mdCloseOnSelect="true"
                :closeOnBlur="true"
              >
                <md-button class="md-icon-button" md-menu-trigger
                  ><md-icon>more_vert</md-icon></md-button
                >

                <md-menu-content>
                  <md-menu-item @click="() => eraser(item.positionArray)"
                    >Zerar quantidade</md-menu-item
                  >
                  <md-menu-item @click="() => refresh(item.positionArray)"
                    >Restaurar quantidade</md-menu-item
                  >
                  <hr />
                  <md-menu-item @click="showDetails(item.positionArray)"
                    >Mais informações</md-menu-item
                  >
                </md-menu-content>
              </md-menu>
            </div>
          </td>

          <td class="tableDrop buttons hideNearTheMobile">
            <TableButtonsAction :item="item.positionArray" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import stringEmptyToHypen from "../../modules/stringEmptyToHyphen";
import TableButtonsAction from "./TableButtonsAction";
import TableQuantityCounter from "./TableQuantityCounter";
import TableSegregation from "./TableSegregation";
import getImage from "../../utils/getImage"
import { CText } from '@chakra-ui/vue'

export default {
  name: "TableProducts",
  props: ["data"],
  components: {
    TableQuantityCounter,
    TableButtonsAction,
    TableSegregation,
    CText
  },
  data() {
    return {
      stringEmptyToHypen,
    };
  },
  methods: {
    getBackground(index) {
      return index % 2 === 0
        ? { backgroundColor: "#F7F7F7", color: "#707070" }
        : { backgroundColor: " #fff", color: "#707070" };
    },
    getImage,
    getUrl(item) {

      if (this.$route.name === "HomeB2b") {
        this.$router.push({ name: "MoreB2b", params: { item: item } })
      } else {
        this.$router.push({ name: "MoreAfv", params: { item: item } })
      }

    },
    showDetails(id) {

      if (this.$route.name === "HomeB2b") {
        this.$router.push({ name: "MoreB2b", params: { item: id } })
      } else {
        this.$router.push({ name: "MoreAfv", params: { item: id } })
      }
    },
    refresh(item) {
      if (this.$store.state.data.items[item].segregacoes.length) {
        const quantidadeOriginal = this.$store.state.data.items[item].quantidadeOriginal

        this.$store.state.data.items[item].quantidade = quantidadeOriginal

        this.$store.commit("setQuantityItem")

      }
      else {
        this.$store.state.data.items[item].quantidade = this.$store.state.data.items[item].quantidadeOriginal
      }
      this.$store.commit("setQuantityItem")
      this.$store.commit('updateItem',
        this.$store.state.data.items[item]
      )
    },
    eraser(item) {
      if (this.$store.state.data.items[item].segregacoes.length) {
        this.$store.state.data.items[item].segregacoes = this.$store.state.data.items[item].segregacoes.map(item => ({ ...item, quantidade: 0, volatil: 0, actionSegregation: false }))
        this.$store.state.data.items[item].quantidade = 0
      }
      else {
        this.$store.state.data.items[item].quantidade = 0
      }
      this.$store.commit("setQuantityItem")
    },
  },

  computed: {
    items() {
      return this.$store.state.itemsPaginate
    },
    itemsLength() {
      return this.$store.state.itemsPaginate.length
    }
  }
};
</script>

<style scoped>
.tableHeadItem.value.showNearTheMobile > span {
  text-align: center !important;
}
.tableCellImg {
  min-height: 50px !important;
  min-width: 50px !important;
  max-height: 50px !important;
  max-width: 50px !important;
  object-fit: contain;
}
.table {
  max-width: 100%;
  min-width: 150px;
  position: relative;
  width: 100%;
}
.tableHead {
}
.tableHeadItem {
  color: #17100d;
  font-family: Oxygen, sans-serif;
  font-size: 12px;
  padding: 2px 10px;
  text-align: left;
}
.tableHeadItem.description {
  text-align: left;
}
.tableHeadItem.buttonsAction {
}
.tableHeadItem.value {
}
.tableHeadItem.value > span {
  display: block;
  white-space: nowrap;
  text-align: left;
}

.tableHeadItem.quantity,
.tableHeadItem.buttonsAction {
  text-align: center;
}
.tableBody {
}
.tableRow {
}
.tableDrop {
  padding: 10px 10px;
}
.tableDrop.quantity {
}
.tableDrop.buttons {
  align-items: center;
  min-height: 80px;
  padding: 15px 10px;
  position: relative;
}
.tableDrop.imageProduct {
  min-width: 60px;
}
.tableDrop.description {
}

.tableDrop.description > .descriptionLegend {
  font-size: 12px;
}
.descriptionLegend {
}
.tableEmptyState {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 10px;
  width: 100%;
  margin-bottom: 50px;
  color: #545454;
}

.tableEmptyStateTitle {
  font-size: 25px;
  margin-bottom: 10px;
}
.tableEmptyStateText {
  font-size: 16px;
}
.moreInformationButton {
  color: #0fa5f0 !important;
  word-break: initial;
}

@media (max-width: 930px) {
  .tableDrop.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 190px !important;
    word-break: break-all;
    width: auto;
  }
}
@media (max-width: 810px) {
  .tableDrop.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 190px !important;
    word-break: break-all;
    width: auto;
  }
}
@media (max-width: 768px) {
  .table {
    display: none;
  }
}
</style>
