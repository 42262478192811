<template>
  <div class="loarderOpacity" v-show="this.$store.state.loaderOpacity">
    <Lottie
      :options="defaultOptions"
      :height="50"
      :width="50"
      v-on:animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import loader from "@/assets/animations/7888-loader-gray-inline.json";

export default {
  name: "LoaderOpacity",
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: loader },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
}
</script>

<style scoped>
.loarderOpacity {
  align-items: center;
  background: rgba(0, 0, 0, 0.534);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 8000;
}
</style>