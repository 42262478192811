<template>
  <div class="cardProducts">
    <div class="card" v-for="(item, index) in itemsPaginate" :key="index">
      <div
        style="display: flex; margin-bottom: 5px"
        @click="showDetails(item.positionArray)"
      >
        <div class="imageProductContainer">
          <img :src="getImage(item.imagem)" alt="" />
        </div>
        <div class="productDataContainer">
          <span class="descriptionLegend" style="color: #707070"
            >{{ item.codigo }}
          </span>
          <span class="productDescription">{{ item.descricao }}</span>
          <span
            class="descriptionLegend"
            style="margin-bottom: 10px; color: #707070"
          >
            Cód. barras: {{ item.ean13 }}
          </span>

          <span class="productValue valueSemImposto"
            >Valor sem imposto:
            {{
              new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
              }).format(item.preco)
            }}</span
          >
          <span class="productValue valueComImposto"
            >Valor com imposto:
            {{
              new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL",
              }).format(item.precoComImpostos)
            }}</span
          >
        </div>
        <div @click.stop>
          <md-menu
            md-size="medium"
            md-align-trigger
            :mdCloseOnClick="true"
            :mdCloseOnSelect="true"
          >
            <md-button class="md-icon-button" md-menu-trigger
              ><md-icon>more_vert</md-icon></md-button
            >

            <md-menu-content>
              <md-menu-item @click="() => eraser(item.positionArray)"
                >Zerar quantidade</md-menu-item
              >
              <md-menu-item @click="() => refresh(item.positionArray)"
                >Restaurar quantidade</md-menu-item
              >
              <hr />
              <md-menu-item @click="showDetails(item.positionArray)"
                >Mais informações</md-menu-item
              >
            </md-menu-content>
          </md-menu>
        </div>
      </div>

      <div class="quantityAndResetButtons">
        <TableQuantityCounter v-show="!item.segregacoes.length" :item="item" />

        <TableSegregation
          v-show="item.segregacoes.length"
          :item="item"
          :id="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TableQuantityCounter from "./TableQuantityCounter";
import TableButtonsAction from "./TableButtonsAction";
import TableSegregation from "./TableSegregation";
import getImage from "../../utils/getImage.js"
import {
  CMenu,
  CMenuButton,
  CMenuList,
  CMenuItem,
  CMenuGroup,
  CMenuDivider,
  CMenuOptionGroup,
  CMenuItemOption,
} from "@chakra-ui/vue";

export default {
  name: "CardProducts",
  components: {
    TableQuantityCounter,
    TableButtonsAction,
    TableSegregation,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuItem,
    CMenuGroup,
    CMenuDivider,
    CMenuOptionGroup,
    CMenuItemOption,

  },
  methods: {
    getImage,
    showDetails(id) {

      if (this.$route.name === "HomeB2b") {
        this.$router.push({ name: "MoreB2b", params: { item: id } })
      } else {
        this.$router.push({ name: "MoreAfv", params: { item: id } })
      }
    },
    refresh(item) {
      if (this.$store.state.data.items[item].segregacoes.length) {
        const quantidadeOriginal = this.$store.state.data.items[item].quantidadeOriginal

        this.$store.state.data.items[item].quantidade = quantidadeOriginal

        this.$store.commit("setQuantityItem")

      }
      else {
        this.$store.state.data.items[item].quantidade = this.$store.state.data.items[item].quantidadeOriginal
      }
      this.$store.commit("setQuantityItem")
      this.$store.commit('updateItem',
        this.$store.state.data.items[item]
      )
    },
    eraser(item) {
      if (this.$store.state.data.items[item].segregacoes.length) {
        this.$store.state.data.items[item].segregacoes = this.$store.state.data.items[item].segregacoes.map(item => ({ ...item, quantidade: 0, volatil: 0, actionSegregation: false }))
        this.$store.state.data.items[item].quantidade = 0
      }
      else {
        this.$store.state.data.items[item].quantidade = 0
      }
      this.$store.commit("setQuantityItem")
    },
  },
  computed: {
    itemsPaginate() {
      return this.$store.state.itemsPaginate
    },

  },

};
</script>

<style scoped>
.cardProducts {
  background: #f7f7f7;
  display: none;
  padding: 0 5px;
}
.moreInformationButton {
  color: #169840 !important;
}
@media (max-width: 768px) {
  .cardProducts {
    display: flex;
    flex-direction: column;
  }
  .card {
    background: #fff;
    border: solid 1px #e4e4e4;
    display: block;
    margin: 5px 0;
    padding: 10px 10px;
  }
  .imageProductContainer {
    margin-right: 15px;
  }
  .imageProductContainer > img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  .productDataContainer {
    display: flex;
    flex-direction: column;
    width: 85%;
  }
  .productDescription {
    font-size: 15px;
    font-weight: 500;
    word-break: break-all;
    margin-bottom: 5px;
  }
  .productCod {
    color: #707070;
    margin-bottom: 8px;
  }
  .productValue {
  }
  .productValue.valueComImposto {
  }
  .productValue.valueSemImposto {
    color: #707070;
  }
  .quantityAndResetButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
  }
  .moreInformationButton {
    color: #169840;
    word-break: initial;
  }
}
</style>