<template>
  <section class="tradeRepresentative">
    <div class="tradeRepresentativeContent">
      <div class="tradeRepresentativeDiv">
        <h2 class="title">Representante Comercial</h2>

        <span class="line"
          >Nome:
          {{ stringEmptyToHypen(data.representanteNome) }}
        </span>
        <span class="line"
          >E-mail :
          {{ stringEmptyToHypen(data.representanteEmail) }}
        </span>
        <span class="line"
          >Telefone :
          {{ stringEmptyToHypen(data.representanteFone) }}
        </span>
      </div>

      <div class="tradeRepresentativeDiv">
        <h2 class="title">Informações do cliente</h2>

        <span class="line"
          >Nome :
          {{ stringEmptyToHypen(data.clienteContato.nome) }}
        </span>
        <span class="line"
          >E-mail :
          {{ stringEmptyToHypen(data.clienteContato.email) }}
        </span>
        <span class="line"
          >Telefone :
          {{ stringEmptyToHypen(data.clienteContato.telefone) }}
        </span>
        <span class="line"
          >CNPJ/CPF :
          {{ stringEmptyToHypen(data.clienteCgccpf) }}
        </span>
        <span class="line"
          >IE/RG :
          {{ stringEmptyToHypen(data.clienteIeRg) }}
        </span>
        <span class="line"
          >Endereço :
          {{ stringEmptyToHypen(data.clienteEndereco.logradouro) }}

          {{ stringEmptyToHypen(data.clienteEndereco.bairro) }}

          {{ stringEmptyToHypen(data.clienteEndereco.cep) }}

          {{ stringEmptyToHypen(data.clienteEndereco.cidade) }}

          -
          {{ stringEmptyToHypen(data.clienteEndereco.uf) }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import stringEmptyToHypen from "../modules/stringEmptyToHyphen";

export default {
  name: "TradeRepresentative",
  data() {
    return {
      stringEmptyToHypen
    }
  },
  computed: {
    data() {
      return this.$store.state.data
    }
  }
};
</script>

<style scoped>
.title {
  text-transform: uppercase;
}
.tradeRepresentative {
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  min-height: 150px;
  padding: 20px 10px;
  width: 100%;
}
.tradeRepresentativeContent {
  display: flex;
  max-width: 100vw;
  width: 1130px;
}
.tradeRepresentativeDiv {
  display: flex;
  flex: 0.5;
  flex-direction: column;
}
.tradeRepresentativeDiv > .title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 5px;
}
.tradeRepresentativeDiv > .representativeLogo {
  width: 100px;
  height: 35px;
  margin-bottom: 5px;
}
.tradeRepresentativeDiv > .line {
  font-family: "Oxygen", sans-serif;
  font-size: 13px;
  font-weight: 800;
  padding: 0 2px;
}

@media (max-width: 768px) {
  .tradeRepresentativeContent {
    flex-direction: column;
    padding: 15px 4%;
  }
  .tradeRepresentativeDiv:nth-child(1) {
    margin-bottom: 20px;
  }
}
</style>