<template>
  <div class="loarderCheck" v-show="loader">
    <Lottie
      :options="defaultOptions"
      :height="450"
      :width="450"
      v-on:animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import loader from "@/assets/animations/39784-check-mark.json";

export default {
  name: "LoaderCheck",
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: loader, loop: false, autoPlay: false },
      animationSpeed: 3,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },

    stop: function () {
      this.anim.stop();
    },

    play: function () {
      this.anim.play();
    },

    pause: function () {
      this.anim.pause();
    },
  },

  computed: {
    loader() {
      return this.$store.state.loaderCheck
    }
  },
  watch: {
    loader(old, news) {
      this.stop();
      this.play()
    }
  }
}
</script>

<style scoped>
.loarderCheck {
  align-items: center;
  background: rgba(0, 0, 0, 0.534);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100vw;
  z-index: 5000;
}
</style>