var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"moreDetails"},[_c('div',{staticClass:"topBar"},[_c('span',{on:{"click":function () { return this$1.$router.back(); }}},[_c('md-icon',[_vm._v("arrow_back")]),_c('h3',[_vm._v("Mais informações")])],1)]),_c('Header',{attrs:{"minimal":true},on:{"show-minimal":_vm.showMinimal}}),_c('div',{class:{ container: true, minimal: _vm.isMinimal, maximo: !_vm.isMinimal }},[_c('div',{staticStyle:{"width":"100%","color":"#000","margin-bottom":"10px"}},[_c('button',{staticClass:"btnBack",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("< Voltar")])]),_c('div',{staticClass:"productHeader"},[_c('LightGallery',{attrs:{"images":_vm.images,"index":_vm.index,"disable-scroll":true},on:{"close":function($event){_vm.index = null}}}),_c('div',{staticClass:"galeryProduct"},[_c('div',{staticStyle:{"height":"300px"},on:{"click":function($event){_vm.index = 0}}},[_c('img',{staticClass:"galeryProductListImage",attrs:{"src":_vm.getImage(_vm.productSelected.imagem)}})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(Array.isArray(_vm.productSelected.imagem)),expression:"Array.isArray(productSelected.imagem)"}],staticClass:"galeryProductList"},_vm._l((_vm.images),function(thumb,thumbIndex){return _c('li',{key:thumbIndex,staticClass:"galeryProductListItem",style:({ right: _vm.currentRight + 'px' }),attrs:{"id":thumbIndex},on:{"click":function () { return _vm.setOrderImages(thumbIndex); }}},[_c('img',{staticClass:"galeryProductListImage",class:{
                minimal: thumbIndex >= 0 ? true : false,
              },attrs:{"src":thumb.url}})])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.images.length < 4 ? false : true),expression:"images.length < 4 ? false : true"}]},[_c('button',{staticClass:"prev",on:{"click":_vm.prev}},[_vm._v("❮")]),_c('button',{staticClass:"next",on:{"click":_vm.next}},[_vm._v("❯")])])]),_c('div',{staticClass:"productDataContainer"},[_c('div',{staticClass:"productData"},[_c('span',{staticClass:"productCod"},[_vm._v(_vm._s(_vm.productSelected.codigo))]),_c('h1',{staticClass:"productName"},[_vm._v(_vm._s(_vm.productSelected.descricao))]),_c('h3',{staticClass:"productCodBar"},[_vm._v(" Cód. Barras: "+_vm._s(_vm.productSelected.ean13)+" ")]),_c('div',{staticClass:"productPrimary"},[_c('span',{staticClass:"productValuePrimary"},[_vm._v("Valor s/impostos")]),_c('span',{staticClass:"productValueSemImposto"},[_vm._v(" "+_vm._s(new Intl.NumberFormat("pt-br", { style: "currency", currency: "BRL", }).format(_vm.productSelected.preco)))])]),_c('span',{staticClass:"productValueComImposto"},[_vm._v("Valor c/impostos: "+_vm._s(new Intl.NumberFormat("pt-br", { style: "currency", currency: "BRL", }).format(_vm.productSelected.precoComImpostos)))])]),_c('div',{staticClass:"quantityContainer"},[_c('div',{staticClass:"quantityContainerHeader"},[_vm._v("Quantidade")]),_c('div',{staticClass:"counterContainer"},[_c('TableQuantityCounter',{directives:[{name:"show",rawName:"v-show",value:(
                !this.$store.state.data.items[_vm.productId].segregacoes.length
              ),expression:"\n                !this.$store.state.data.items[productId].segregacoes.length\n              "}],attrs:{"item":this.$store.state.data.items[_vm.productId]}}),_c('TableSegregation',{directives:[{name:"show",rawName:"v-show",value:(
                this.$store.state.data.items[_vm.productId].segregacoes.length > 0
              ),expression:"\n                this.$store.state.data.items[productId].segregacoes.length > 0\n              "}],attrs:{"item":this.$store.state.data.items[_vm.productId],"id":_vm.productId}}),_c('TableButtonsAction',{attrs:{"item":_vm.productId,"disabledInfo":true}})],1)])])],1),(_vm.productSelected.detalhesProduto)?_c('div',{staticClass:"productDescription"},[_c('div',{staticClass:"tabs"},[_c('button',{staticClass:"tabsButtons active",style:({ cursor: 'default' })},[_vm._v(" ficha técnica ")])]),_c('div',{staticClass:"tabsContent"},[_c('div',[_c('ul',{staticClass:"listDatasheet"},_vm._l((_vm.fichaDeDados),function(item,index){return _c('li',{key:index,staticClass:"datasheetItem"},[_c('span',{staticClass:"datasheetItemCategory"},[_vm._v(_vm._s(item.categ))]),_c('span',{staticClass:"datasheetItemDescription"},[_vm._v(_vm._s(item.item))])])}),0)])])]):_c('div',{staticClass:"productDescriptionNotFound"},[_vm._v(" ficha tecnica não disponivel ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }