<template>
  <div class="header_bottom">
    <div class="resetButtonsContainer col">
      <md-button class="resetButtons eraser" @click="eraseAllQuantity">
        <img :src="require('@/assets/icons/eraser.svg')" alt="" class="resetButtonsIcon" />
        <span class="hideNearTheMobile">zerar quantidades</span>
        <span class="showNearTheMobile">zerar quant.</span>
      </md-button>

      <md-button class="resetButtons refresh" @click="refreshAllQuantity">
        <img :src="require('@/assets/icons/refresh.svg')" alt="" class="resetButtonsIcon" />
        <span class="hideNearTheMobile">RESTAURAR quantidades</span>
        <span class="showNearTheMobile">RESTAURAR quant.</span>
      </md-button>
    </div>

    <div class="containerInputSearchWidth col">
      <div class="containerInputSearch">
        <input class="inputSearch" placeholder="Pesquise produtos por código ou descrição" type="text"
          v-model.trim="search" @input="searchOnTable" />
        <md-icon class="containerInputSearch_icon" v-show="search.length" @click.native="clearSearch">close</md-icon>
        <md-icon class="containerInputSearch_icon">search</md-icon>
      </div>

      <div class="filter-container">
        <span v-if="filterCount > 0">{{ filterCount }}</span>
        <img :src="require('@/assets/icons/filtro.svg')" alt="" class="filtro-button" @click="openFilter" />
      </div>
    </div>
  </div>
</template>

<script>
import { searchFilter } from "../../utils/searchFilter.js";
import { debounce } from "../../utils/debounce.js";

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {

  let mol = items.filter((item) => {
    let reg = new RegExp(toLower(term))

    return toLower(item.descricao).match(reg) || toLower(item.codigo).match(reg)

  }

  );
  return mol
}
export default {
  name: "HeaderBottom",

  data: () => ({
    search: "",
    searched: [],
  }),
  computed: {
    filterCount() {
      let count = 0;

      for (let key in this.$store.state.filtrosAtivos) {
        if (key !== "busca") {
          if (this.$store.state.filtrosAtivos[key]) {
            count++;
          }
        }
      }

      return count;
    }
  },
  methods: {
    clearSearch() {
      this.search = ""
      this.searchOnTable()
    },
    openFilter() {
      this.$store.commit("setShowFilter", true);
    },
    searchOnTable: debounce(function () {
      this.$store.commit("updateSearch", this.search);

      this.$store.commit(
        "setItems",
        searchFilter(this.$store.state.data.items, this.$store.state.filtrosAtivos, this.$store.state.orderItems)
      );

    }, 300),
    eraseAllQuantity() {
      this.$store.commit("eraseAllQuantity")
    },
    refreshAllQuantity() {
      this.$store.commit("refreshAllQuantity")
    }
  },
  created() {
    this.searched = this.$store.state.data.items;
  },
};
</script>


<style>
.md-button .md-ripple {
  padding: 0 !important;
}
</style>
<style scoped>
.md-button-content {
  display: flex !important;
}

.md-button {
  margin-left: 0 !important;
}
</style>

<style scoped>
.col {
  flex: 1;
}

.header_bottom {
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  padding: 5px 0px;
  width: 1130px;
}

@media (max-width: 1139px) {
  .header_bottom {
    padding: 5px 10px;
    width: 100%;
  }
}

.resetButtonsContainer {
  margin-right: 0px;
  display: flex;
}

.resetButtons {
  align-items: center;
  background: var(--unnamed-color-ffffff);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 6px 8px;
  text-transform: uppercase;
  cursor: pointer;
}

.resetButtons.eraser {
  border: solid 1px var(--unnamed-color-d9290b);
  color: var(--unnamed-color-d9290b);
  font-weight: 100;
}

.resetButtons.refresh {
  border: solid 1px var(--unnamed-color-169840);
  color: var(--unnamed-color-169840);
  font-weight: 100;
}

.resetButtonsIcon {
  margin-right: 10px;
}

.containerInputSearchWidth {
  display: flex;
  gap: 10px;
}

.filter-container {
  display: flex;
  align-items: center;
  position: relative;
}

.filter-container span {
  position: absolute;
  top: 5px;
  right: 0;
  background-color: #2AA22E;
  color: #fff;
  width: 15px;
  height: 15px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.filtro-button {
  width: 24px;
  cursor: pointer;
}

.containerInputSearch {
  background: #fff;
  border: solid 1px var(--unnamed-color-e4e4e4);
  border-radius: 4px;
  display: flex;
  margin: 5px 0;
  padding: 0 10px;
  width: 100%;
}

.containerInputSearch_icon {
  color: #707070;
}

.inputSearch {
  color: #707070;
  height: 100%;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  width: 100%;
  font-style: italic;
}

.inputSearch:focus {
  outline: none;
}
</style>
