<template>
  <div :show="show"></div>
</template>

<script>
import axios from 'axios'
export default {
  name: "ModalConfirmation",
  methods: {
    save() {
      this.$store.dispatch('saveAndSendEstimate', 'send')
    },
  },
  computed: {
    show() {
      return this.$store.state.modalSend
    },
    isB2b() {
      if (this.$route.name.match("B2b")) {
        return 'Ao enviar o orçamento para o representante não será possível alterar esse pedido. Novos pedidos podem ser digitados utilizando esse mesmo link.'

      } else {
        return 'Ao enviar o orçamento para o representante você não terá mais acesso a essa página'
      }
    }
  },
  watch: {
    show(news, old) {
      if (news === true) {
        this.$swal.fire({
          title: 'Confirma o envio do orçamento ?',
          html: this.isB2b,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          reverseButtons: true,
          confirmButtonText:
            'SIM, ENVIAR ORÇAMENTO',
          confirmButtonAriaLabel: 'SIM, ENVIAR ORÇAMENTO',
          confirmButtonColor: " #169840",
          cancelButtonColor: " #E4E4E4",
          cancelButtonText:
            'NÃO, VOLTAR',
          cancelButtonAriaLabel: 'NÃO, VOLTAR'
        }).then((result) => {
          if (result.value) {
            this.save()
          }
        })
      }
      this.$store.commit("setModalSend", false)
    }
  },

}
</script>

<style>
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #757575;
  color: #17100d !important;
  font-size: 1.0625em;
}
.swal2-close:focus {
  box-shadow: none !important;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  line-height: 28px;
}
</style>

<style  scoped>
.btnCancel,
.btnSend {
  border-radius: 3px;
  color: #fff;
  font-family: "Roboto";
  font-size: 18px;
  margin: 0 10px;
  padding: 10px 15px;
}
.btnSend {
  background: rgb(22, 152, 64);
}
.btnCancel {
  background: #e4e4e4;
}
.orange {
  color: #ff7300a8 !important;
}
.icon {
  font-size: 120px !important;
}
.modalHeader {
  align-items: center;
  display: flex;
  height: 150px;
  justify-content: center;
  width: 100%;
}
.modalBody {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.buttonGroup {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}
.modalTitle {
  color: #595959;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
}
.modalSubTitle {
  color: #7e7e7e;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  width: 75vw;
}
</style>

