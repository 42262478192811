<template>
  <div class="error">
    <TopBarOnlyLogo />
    <div class="content">
      <h1 class="title">Orçamento não disponível</h1>
      <h1 class="subTitle">
        Tente novamente mais tarde ou contate o seu representante comercial
      </h1>
    </div>
  </div>
</template>

<script>
import TopBarOnlyLogo from '../components/TopBarOnlyLogo.vue'

export default {
  name: "Erro",
  components: {
    TopBarOnlyLogo
  }
}
</script>

<style scoped>
.error {
  width: 100%;
  height: 100vh;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.content > .title,
.content > .subTitle {
  font-family: "Oxygen", sans-serif;
  color: #707070;
}
.content > .title {
  font-size: 30px;
  font-weight: 100;
  margin-bottom: 15px;
}
.content > .subTitle {
}

@media (max-width: 480px) {
  .content {
    padding: 0 10px;
  }
  .content > .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .content > .subTitle {
    font-size: 15px;
    text-align: center;
  }
}
</style>