<template>
  <transition name="fade">
    <div class="loader" v-show="loader">
      <img
        :src="require('../assets/images/orcamento_logo.svg')"
        alt=""
        class="loaderImg"
      />
      <Lottie
        :options="defaultOptions"
        :height="50"
        :width="50"
        v-on:animCreated="handleAnimation"
      />
    </div>
  </transition>
</template>

<script>
import Lottie from "vue-lottie";
import loader from "../assets/animations/lf20_BNdbOI.json";
export default {
  name: "Loader",
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: loader },
      animationSpeed: 1,
    };
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
  computed: {
    loader() {
      return this.$store.state.loader
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.loader {
  background: #f7f7f7;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 3000;
}
.loaderImg {
  width: 300px;
  height: 250px;
}
</style>
