<template>
  <div>
    <router-view></router-view>

    <div v-if="askPassword" class="askPassword">
      <img :src="require('../assets/images/logo-orcamento.png')" alt="Orçamento Web" class="propostaLogo" />

      <div class="card">
        <h2>Desbloqueie o acesso</h2>

        <form @submit.prevent="handleDocumento">
          <div class="card__field">
            <label>Nº do Documento</label>
            <button class="card_copy" disabled>
              {{ documentNumber($store.state.data['clienteCgccpf']) }}
            </button>
          </div>

          <div class="card__field">
            <label>Razão social</label>
            <button class="card_copy" disabled>
              {{ hiddenRazaoSocial($store.state.data['clienteRazaoSocial']) }}
            </button>
          </div>

          <div class="card__field">
            <label>Primeiros 5 dígitos do documento</label>
            <input type="text" :value="documento" maxlength="5" @input="event => documento = event.target.value" />
          </div>

          <div class="buttonContainer">
            <button type="submit">Acessar</button>
          </div>
        </form>
      </div>
    </div>

    <div v-if="showScreenByRouter && !askPassword">
      <div class="home" v-if="showPage">
        <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration"
          :md-active.sync="showSnackbar" md-persistent>
          <span>Connection timeout. Showing limited messages!</span>
          <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
        </md-snackbar>

        <Header />
        <HeaderMobile />
        <CardsEnterprise />
        <div :class="{ container: true, minimal: !padding, maximo: padding }">
          <TableProducts />
          <CardProducts />

          <div v-show="itemsPaginateLength !== 0">
            <v-page :total-row="itemsLengths" v-model="current" @page-change="pagePhotoChange" align="right"
              :border="false" :first="true" :last="true" :page-number="false"></v-page>
          </div>
        </div>
        <Observation charLimited="200" :data="this.$store.state.data" />
        <TradeRepresentative />

        <Footer />

        <BottomBar />
        <BottomBarMobile />
        <FilterComponent @close="closeFilter" />
      </div>
    </div>
  </div>
</template>

<script>
import BottomBar from "../components/BottomBar/BottomBar";
import BottomBarMobile from "../components/BottomBar/BottomBarMobile";
import Header from "../components/Header/Header";
import HeaderMobile from '../components/Header/HeaderMobile'
import Observation from "../components/Observation";
import TableProducts from "../components/TableAndCardProducts/TableProducts";
import TradeRepresentative from "../components/TradeRepresentative.vue";
import Footer from "../components/Footer/Footer";
import stringEmptyToHypen from "../modules/stringEmptyToHyphen";
import CardProducts from '../components/TableAndCardProducts/CardProducts';
import CardsEnterprise from '../components/CardsEnterprise.vue';
import FilterComponent from "../components/Filter/Filter.vue";


export default {
  name: "Home",
  components: {
    Header,
    HeaderMobile,
    BottomBar,
    BottomBarMobile,
    TableProducts,
    Observation,
    TradeRepresentative,
    Footer,
    CardProducts,
    CardsEnterprise,
    FilterComponent,
  },
  data() {
    return {
      arr: [],
      itemsLength: [],
      current: 1,
      pageArr: [],
      textarea: "",
      flag: false,
      pageSize: 10,
      stringEmptyToHypen,
      showSnackbar: this.$store.state.toastSave,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      let: 0,
      askPassword: true,
      documento: "",
    };
  },

  methods: {
    closeFilter() {
      this.$store.commit("setShowFilter", false);
    },
    pagePhotoChange(pInfo) {
      this.pageSize = pInfo.pageSize

      if (this.items !== null) {
        this.pageArr.splice(0, this.pageArr.length);
        let start = 0,
          end = 0;

        start = pInfo.pageSize * (pInfo.pageNumber - 1);
        end = start + pInfo.pageSize;

        if (end > this.$store.state.items.length) end = this.$store.state.items.length;
        for (let i = start; i < end; i++) {
          this.pageArr.push(this.$store.state.items[i]);
        }

        this.$store.commit("setItemsPaginate", this.pageArr)

        this.$store.commit("updateVPage", pInfo.pageNumber)
      } else {
        return;
      }
    },
    showImagemEmpresa(item) {
      return item.imagemEmpresa === "" ? false : true;
    },
    documentNumber(document) {
      return document.replace(/\d(?=(?:\D*\d){4})/g, '*');
    },
    hiddenRazaoSocial(name) {
      const split = name.split(" ");

      const hidden = split.map(part => {
        const rg = /\B\w/g;

        part = part.trim().replace(rg, "*");

        return part;
      });

      return hidden.join(" ");
    },
    handleDocumento() {
      const original = this.$store.state.data["clienteCgccpf"].replace(/[\D]/g, "").slice(0, 5);

      if (original === this.documento) {
        const showResetQuantityModal = localStorage.getItem("showResetQuantityModal");
        document.querySelector(".askPassword").style.opacity = 0;

        setTimeout(() => {
          this.askPassword = false;
        }, 300);

        if (this.$store.state.shouldSetQuantityToZero && showResetQuantityModal !== false) {
          this.$swal({
            title: "Atenção",
            text: "Deseja iniciar um novo pedido sem qualquer quantidade anterior?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            confirmButtonColor: "#169840",
            cancelButtonText: "Não",
            cancelButtonColor: "#eee",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store.commit("resetAllQuantity");
              localStorage.setItem("shouldZeroQuantity", true);
            } else {
              localStorage.setItem("shouldZeroQuantity", false);
            }

            localStorage.setItem("showResetQuantityModal", false);
          });
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Atenção',
          text: "Documento inválido",
          confirmButtonColor: "#169840",
          focusConfirm: false,
        })
      }
    },
  },
  computed: {
    padding() {
      return this.$store.state.headerShowMinimal
    },
    showScreenByRouter() {
      if (this.$route.name === "MoreB2b" || this.$route.name === "MoreAfv") {
        return false
      } else {
        return true
      }
    },

    datas() {
      return this.$store.state.data;
    },
    items() {
      return this.$store.state.items;
    },
    filters() {
      return this.$store.state.filtros;
    },
    itemsPaginate() {
      return this.$store.state.itemsPaginate
    },
    itemsPaginateLength() {
      return this.$store.state.itemsPaginate.length
    },
    itemsLengths() {
      return this.$store.state.items.length
    },
    showPage() {
      return this.$store.state.showPage
    }
  },
  mounted() {
    if (this.datas === undefined) {
      this.$store.dispatch("getData", {
        cpfCnpj: this.$route.params.cpfCnpj,
        numPedido: this.$route.params.numPedido,
      });
    }
    this.$store.state.flagControl = false
    localStorage.setItem('setCurrentRoute', null);
  },
  watch: {
    items() {
      //if (this.flag === false) {
      this.itemsLength = this.$store.state.data.items;
      this.pagePhotoChange({ pageNumber: 1, pageSize: 10 });
      //this.flag = true
      //}
    },
    itemsPaginate(news, old) {

      if (news.length > this.pageSize) {

        this.pagePhotoChange({
          pageNumber: 1,
          pageSize: this.pageSize
        })
      }
    }
  },
};
</script>


<style>
.et-wrapper.et-top {
  top: 10px !important;
  bottom: auto;
}

.et-wrapper.green {
  background-color: #169840;
}

.et-wrapper .et-close {
  position: absolute;
  top: 0;
  right: 5px;
  color: #fff !important;
}

@media (max-width: 768px) {
  .et-wrapper.et-top {
    top: 70px !important;
    bottom: auto;
  }
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
  border-color: #d3d8e000 !important;
}

.md-focused {
  caret-color: #cbcbcb !important;
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
  border-color: #cbcbcb;
}

.md-button-content {
  display: flex !important;
}

.md-button {
  margin-left: 10px !important;
}

.md-button:not([disabled]).md-focused:before,
.md-button:not([disabled]):active:before,
.md-button:not([disabled]):hover:before {
  background-color: currentColor;
  opacity: 0;
}

.td-container {
  padding: 0px !important;
  margin-left: 10px !important;
}

.th-container,
.th-label {
  overflow: visible !important;
  text-align: inherit;
}

.th-container {
  height: auto !important;
}
</style>
<style scoped>
.minimal {
  padding-top: 250px;
}

.maximo {
  padding-top: 150px;
}

.container {
  max-width: 1130px;
  margin: 0 auto;
}

.home {
  position: relative;
  background: #fff;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .container {
    padding-top: 0px;
  }
}

.propostaLogo {
  max-width: 300px;
}

.askPassword {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f7f7f7;
  z-index: 1055;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity .3s;
}

.askPassword .card {
  margin-top: 30px;
  background-color: #fefefe;
  padding: 30px;
}

.askPassword .card h2 {
  font-size: 1.8rem;
  line-height: 100%;
  margin-bottom: 20px;
  text-transform: uppercase;
}

@media (max-width: 400px) {
  .askPassword .card h2 {
    font-size: 1.25rem;
  }
}

.askPassword .buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.askPassword .card button {
  padding: 8px 20px;
  text-transform: uppercase;
  background-color: #3c8a42;
  color: #fff;
  font-weight: bold;
  transition: background-color .2s;
  cursor: default;
}

.askPassword .card__field label {
  font-size: 13px;
  color: #757575;
  text-transform: uppercase;
}

.askPassword .card__field input {
  height: 40px;
  border: 1px solid #eee;
  padding: 10px;
}

.askPassword .card__field {
  display: flex;
  flex-direction: column;
}

.card_copy {
  font-weight: normal !important;
  margin-bottom: 20px;
}
</style>
