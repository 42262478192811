<template>
  <header class="header">
    <HeaderTop @show-minimal="showMinimal" />
    <hr />
    <HeaderBottom v-show="minimal === false" />
  </header>
</template>

<script>
import HeaderBottom from "./HeaderBottom.vue";
import HeaderTop from "./HeaderTop.vue";

export default {
  name: "Header",
  props: {
    minimal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HeaderTop,
    HeaderBottom,
  },
  methods: {
    showMinimal(event) {

      this.$emit("show-minimal", event)
    }
  }
};
</script>

<style scoped>
hr {
  width: 100%;
}
.header {
  align-items: center;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000033;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0px;
  margin-bottom: 20px;
  opacity: 1;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 500;
}
@media (max-width: 768px) {
  .header {
    display: none;
  }
}
</style>
