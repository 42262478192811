<template>
  <div class="bottomBar">
    <div class="bottomBarContent">
      <div class="valuesContainer comImposto">
        <span class="title">Total s/impostos:</span>
        <span class="valueCash">{{
          new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL",
            currencyDisplay: "symbol",
          }).format(semImposto)
        }}</span>
      </div>

      <div class="valuesContainer semImposto">
        <span class="title">Total aprox. c/impostos:</span>
        <span class="valueCash">{{
          new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL",
          }).format(comImposto)
        }}</span>
      </div>

      <md-button
        class="bottomBarActions save"
        @click="() => this.$store.dispatch('saveAndSendEstimate', 'save')"
      >
        <span class="hideNearTheMobile">Salvar e continuar depois</span>
        <span class="showNearTheMobile"> salvar</span>
      </md-button>

      <md-button
        class="bottomBarActions send"
        @click="() => this.$store.commit('setModalSend', true)"
      >
        <span class="hideNearTheMobile">Enviar p/ representante</span>
        <span class="showNearTheMobile">Enviar</span>
      </md-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomBar",
  computed: {
    semImposto() {
      return this.$store.state.valorSemImposto;
    },
    comImposto() {
      return this.$store.state.valorComImposto;
    },
  },
};
</script>

<style scoped>
.bottomBar {
  align-items: center;
  background: #f7f7f7;
  bottom: 0;
  box-shadow: #00000033 0px -2px 4px;
  display: flex;
  height: 60px;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1054;
  padding: 0 10px;
}
.bottomBarContent {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  max-width: 1130px;
  width: 100%;
}
.bottomBarActions.save {
  background: #3e6e31;
  color: #ffffff;
  font-size: 13px;
  margin: 0 2px;
}
.bottomBarActions.send {
  background: #169840;
  color: #ffffff;
  font-size: 13px;
  margin: 0 2px;
}
.bottomBarActions {
  padding: 12px 22px;
  align-items: center;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  font-family: "Oxygen", sans-serif;
  height: 40px;
  justify-content: space-around;
  text-transform: uppercase;
}
.valuesContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  text-align: right;
}
.valuesContainer.comImposto {
  color: #707070;
  font-family: "Roboto", sans-serif;
}
.valuesContainer.semImposto {
  color: #17100d;
  font-family: "Roboto", sans-serif;
}
.valuesContainer > .title {
}
.valuesContainer > .valueCash {
  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 768px) {
  .bottomBar {
    display: none;
  }
}
</style>
