<template>
    <div :class="{ filter_wrapper: true, open: isOpen }">
        <div class="filter_overlay" @click="closeFilter"></div>
        <div class="filter">
            <div class="filter_header">
                <p>Filtrar</p>
                <button @click="clearFilter">Limpar filtros</button>
            </div>

            <div class="filter_body">
                <span style="font-size: 12px;">Ordenar por</span>
                <hr />
                <div class="filter_section">
                    <select id="order" v-model="ordenacao">
                        <option value="">Selecione</option>
                        <option value="ref">Código / Referência</option>
                        <option value="desc">Descrição</option>
                    </select>
                </div>

                <span style="font-size: 12px;margin-top:16px;display:block;">Filtrar por</span>
                <hr />
                <div class="filter_section" v-for="(property, propertyName) in filterObject" :key="propertyName">
                    <label :for="propertyName">{{ property.label }}</label>
                    <select :id="propertyName" v-model="selecteds[propertyName]">
                        <option value="">Selecione</option>
                        <option v-for="(option, index) in property.options" :value="option" :key="index">{{ option }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="filter_footer">
                <button @click="filter">Aplicar filtros</button>
            </div>
        </div>
    </div>
</template>

<script>
import { searchFilter } from '../../utils/searchFilter';

export default {
    name: "FilterComponent",
    data() {
        return {
            selecteds: {
                segmento: "",
                linha: "",
                grupo: "",
                subgrupo: "",
                descFornecedor: "",
            },
            ordenacao: "",
        }
    },
    computed: {
        filterObject() {
            return this.$store.state.filtros;
        },
        isOpen() {
            return this.$store.state.showFilter;
        }
    },
    methods: {
        filter() {
            this.$store.commit('setCurrentFilter', this.selecteds);
            this.$store.commit('setOrdersItems', this.ordenacao);
            this.$store.commit('setItems', searchFilter(this.$store.state.data.items, this.$store.state.filtrosAtivos, this.$store.state.orderItems));
            this.$emit('close');
        },
        clearFilter() {
            this.selecteds = {
                segmento: "",
                linha: "",
                grupo: "",
                subgrupo: "",
                descFornecedor: "",
            }
            this.ordenacao = "";
            this.$store.commit('setCurrentFilter', this.selecteds);
            this.$store.commit('setOrdersItems', this.ordenacao);
        },
        closeFilter() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.filter_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}

.filter_wrapper:not(.open) {
    pointer-events: none;
}

.filter_wrapper:not(.open) .filter_overlay {
    display: none;
}

.filter_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.22);
}

.filter {
    width: 280px;
    height: 100vh;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    padding: 20px;
    padding-right: 35px;
    display: flex;
    flex-direction: column;
    transition: right 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .filter {
        padding-right: 20px;
    }
}

.filter_wrapper:not(.open) .filter {
    right: -280px;
}

.filter_wrapper.open .filter {
    right: 0;
}

.filter_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
}

.filter_body {
    flex: 1;
}

.filter_footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    border-top: 1px solid #ccc;
}

.filter button {
    background-color: #3c8a42;
    color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
}

.filter_section {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.filter_section label {
    font-size: 12px;
}

.filter_section select {
    border: 1px solid #ccc;
    height: 30px;
}
</style>