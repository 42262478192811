<template>
  <c-theme-provider>
    <Loader />
    <LoaderOpacity />
    <LoarderCheck />
    <ModalSaveSucess />
    <ModalSendSucess />
    <c-reset />
    <router-view />
  </c-theme-provider>
</template>

<script>
import Loader from './views/Loader'
import LoaderOpacity from './components/Loader/LoarderOpacity'
import LoarderCheck from './components/Loader/LoarderCheck.vue';
import ModalSaveSucess from './components/Modals/ModalSaveSucess.vue';
import ModalSendSucess from './components/Modals/ModalSendSucess.vue';

export default {
  name: "App",
  components: {
    Loader,
    LoaderOpacity,
    LoarderCheck,
    ModalSaveSucess,
    ModalSendSucess
  },

  async mounted() {

    this.$store.dispatch("getData", {
      cpfCnpj: this.$route.params.cpfCnpj,
      numPedido: this.$route.params.numPedido,
      tradeAgent: this.$route.params.tradeAgent,
      cpfCnpjCustomer: this.$route.params.cpfCnpjCustomer
    });
  }

};
</script>
