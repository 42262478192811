<template>
  <div class="bottomBarMobile">
    <div class="values left">
      <span class="descriptionOfValue">Total s/impostos:</span>
      <span class="valueNumber">{{
        new Intl.NumberFormat("pt-br", {
          style: "currency",
          currency: "BRL",
          currencyDisplay: "symbol",
        }).format(semImposto)
      }}</span>
    </div>

    <div class="values rigth">
      <span class="descriptionOfValue">Total c/impostos:</span>
      <span class="valueNumber">{{
        new Intl.NumberFormat("pt-br", {
          style: "currency",
          currency: "BRL",
        }).format(comImposto)
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomBarMobile",
  computed: {
    semImposto() {
      return this.$store.state.valorSemImposto;
    },
    comImposto() {
      return this.$store.state.valorComImposto;
    },
  },
};
</script>

<style scoped>
.bottomBarMobile {
  display: none;
}
@media (max-width: 768px) {
  .bottomBarMobile {
    background: #d31b1b;
    bottom: 0;
    display: flex;
    height: 56px;
    left: 0;
    position: fixed;
    width: 100vw;
    z-index: 2000;
  }
  .values {
    align-items: flex-end;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    width: 50vw;
    overflow-wrap: anywhere;
  }
  .descriptionOfValue {
    font-family: "Oxygen", sans-serif;
    font-size: 12px;
  }
  .valueNumber {
    font-size: 18px;
    font-weight: 100;
  }
  .left {
    background: #3e6e31;
  }
  .rigth {
    background: #169840;
  }
}
</style>
