<template>
  <div class="observation">
    <div class="observationDescription">
      <span class="textAreaLabel">{{ "Observações".toUpperCase() }}</span>
    </div>

    <md-field>
      <md-textarea
        id="textarea"
        v-model="$store.state.data.observacao"
        :md-counter="charLimited === undefined ? 50 : charLimited"
        :maxlength="charLimited === undefined ? 50 : charLimited"
      ></md-textarea>
    </md-field>
  </div>
</template>

<script>
export default {
  name: "Observation",
  props: ["charLimited", "data"],
};
</script>

<style scoped>
.observation {
  margin-top: 30px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
}
.observationDescription {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  max-width: 1130px !important;
  width: 100%;
}
.observationDescription > .textAreaLabel {
  color: #707070;
}
.observationDescription > .expireDate {
  align-items: center;
  color: #d9290b;
  text-align: right;
}
.iconRed {
  color: #d9290b !important;
  margin-right: 5px;
}
#textarea {
  outline: #707070;
  resize: none;
  width: 1130px !important;
  min-height: 65px !important;
}
.md-field {
  max-width: 1130px;
  width: 100%;
  min-height: 48px;
  margin: 4px 0 24px;
  padding-top: 16px;
  display: flex;
  position: relative;
  font-family: inherit;
}
@media (max-width: 979px) {
  .md-field {
    width: 100%;
    min-height: 48px;
    margin: 4px 0 24px;
    padding-top: 16px;
    display: flex;
    position: relative;
    font-family: inherit;
  }
  .observationDescription {
    width: 100% !important;
  }

  #textarea {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .observationDescription > .textAreaLabel {
    color: #000;
    font-weight: 600;
  }
  .observation {
    margin-top: 30px;

    background: #fff;
  }
}
</style>
