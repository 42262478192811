import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Chakra, { CThemeProvider, CReset } from "@chakra-ui/vue";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import VModal from "vue-js-modal";
import Toast from "vue-easy-toast";
import LightGallery from "vue-light-gallery";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Page from "./components/vPage/index";
Vue.use(Page, {
  language: "pt",
});

import "./assets/css/global.css";

Vue.use(Toast);
Vue.use(VModal);
Vue.use(Chakra);
Vue.use(VueMaterial);
Vue.use(VueSweetalert2);
Vue.use(LightGallery);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(CThemeProvider, [h(CReset), h(App)]),
}).$mount("#app");
