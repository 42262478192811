import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Erro from "../views/Erro";
import Success from "../views/Success";
import Expirado from "../views/Expirado";
import MoreInformation from "../views/MoreInformation";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:cpfCnpj/:numPedido",
    name: "HomeAfv",
    component: Home,
    children: [
      {
        path: "more/:item",
        name: "MoreAfv",
        component: MoreInformation,
      },
    ],
  },
  {
    path: "/:cpfCnpj/:numPedido/:tradeAgent/:cpfCnpjCustomer",
    name: "HomeB2b",
    component: Home,
    children: [
      {
        path: "more/:item",
        name: "MoreB2b",
        component: MoreInformation,
      },
    ],
  },

  {
    path: "/error",
    name: "Error",
    component: Erro,
  },

  {
    path: "/success",
    name: "Success",
    component: Success,
  },
  {
    path: "/expirado",
    name: "Expirado",
    component: Expirado,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
